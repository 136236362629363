
export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyCuJFhpKjEYEQ37Wkh51N6fVel9TCAV9Sg',
    authDomain: 'agile-retrospective-6f3a0.firebaseapp.com',
    databaseURL: 'https://agile-retrospective-6f3a0.firebaseio.com',
    projectId: 'agile-retrospective-6f3a0',
    storageBucket: 'agile-retrospective-6f3a0.appspot.com',
    messagingSenderId: '466997433892'
  }
};
